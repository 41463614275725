<template>
  <div>
    <FormsRegisterForm />

    <button @click.prevent="openLoginForm">
      Schon ein Konto? Login
    </button>
  </div>
</template>

<script setup lang="ts">
defineEmits(['close'])

const { openModal } = useModal()

function openLoginForm() {
  openModal('Login')
}
</script>
