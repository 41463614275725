<template>
  <div class="flex items-center justify-between">
    <h2 class="typo-teaser-3">Registrieren</h2>

    <ModalCloseButton />
  </div>
  <form class="typo-body-1" @submit.prevent>
    <FormsTextInput
      v-model="email"
      name="email"
      :error-message="emailError"
      placeholder="E-Mail-Adresse"
      :validation="validateEmail"
    />

    <FormsTextInput
      v-model="username"
      name="username"
      :error-message="usernameError"
      placeholder="Benutzername"
      :validation="validateUsername"
    />

    <FormsTextInput
      v-model="password"
      name="password"
      :error-message="passwordError"
      placeholder="Nachname"
      :validation="validatePassword"
    />

    <NPCommonDefaultButton
      class="mt-8"
      :disabled="buttonDisabled"
      @click="submit"
    >
      Registrieren
    </NPCommonDefaultButton>
  </form>
</template>

<script setup lang="ts">
const email = ref('')
const emailError = ref('')

function validateEmail() {
  emailError.value = useFormValidation().validateEmailInput(email.value)
}

const username = ref('')
const usernameError = ref('')
function validateUsername() {
  if (username.value === '') {
    usernameError.value = 'Bitte gib eine Email-Adresse ein'
  }

  usernameError.value = ''
}

const password = ref('')
const passwordError = ref('')
function validatePassword() {
  passwordError.value = ''

  if (password.value !== '') {
    return
  }

  passwordError.value = 'Bitte gib dein Passwort ein.'
}

const buttonDisabled = computed(
  () => email.value === '' || username.value === '' || password.value === '',
)

function submit() {
  emailError.value = ''
  usernameError.value = ''
  passwordError.value = ''

  validateEmail()
  validateUsername()
  validatePassword()

  if (
    email.value !== ''
    || usernameError.value !== ''
    || passwordError.value !== ''
  ) {
    return
  }

  console.log('submit')
}
</script>
